import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import FooterSection from '@/components/shared/footer/FooterSection.vue';
import BlondWhatsapp from "@/components/shared/blondWhatsapp/BlondWhatsapp.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { name: "menu" }),
    _createVNode(_component_router_view),
    _createVNode(BlondWhatsapp),
    _createVNode(FooterSection)
  ], 64))
}
}

})