import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

import SectionServices from '@/components/home/sectionMyServices/SectionServices.vue';
import SectionHeroMain from '@/components/home/sectionHeroMain/SectionHeroMain.vue'
import SectionBrands from '@/components/home/sectionBrands/SectionBrands.vue';
import SectionBenefits from '@/components/home/sectionBenefits/SectionBenefits.vue'
import SectionCatalogue from '@/components/home/sectionCatalogue/SectionCatalogue.vue';
import SectionStatistic from '@/components/home/sectionMyStatistic/SectionStatistic.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SectionHeroMain),
    _createVNode(SectionBrands, { id: "brands" }),
    _createVNode(SectionCatalogue),
    _createVNode(SectionBenefits),
    _createVNode(SectionStatistic),
    _createVNode(SectionServices)
  ]))
}
}

})