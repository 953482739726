import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/icon-catalogue.svg'
import _imports_1 from '@/assets/icons/icon-shopping_bag.svg'
import _imports_2 from '@/assets/icons/icon-bottom.svg'


const _hoisted_1 = { class: "section-main" }
const _hoisted_2 = { class: "content-section-main" }
const _hoisted_3 = { class: "section-btn" }

import { defineAsyncComponent } from 'vue'
import ButtonAction from '@/components/shared/button/ButtonAction.vue';




export default /*@__PURE__*/_defineComponent({
  __name: 'SectionHeroMain',
  setup(__props) {

const VideoBackground = defineAsyncComponent({
  loader: () => import('@/components/videoBackground/VideoBackground.vue'),
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(VideoBackground)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "content" }, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", null, [
              _createElementVNode("img", {
                src: "https://res.cloudinary.com/dyikvzcke/image/upload/v1729912683/skf_fhaoeg.png",
                alt: "Icono de distribuidor Autorizado de SKF"
              })
            ]),
            _createElementVNode("h1", null, "Impulsa tu camino con nuestros repuestos ")
          ]),
          _createElementVNode("h2", { class: "subtitle" }, [
            _createTextVNode(" Expertos en Repuestos para la Industria y Automotriz: "),
            _createElementVNode("span", null, "Calidad, Variedad y Asesoría Especializada")
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(ButtonAction, {
              "type-button": 'btn btn-secondary',
              view: 'productos'
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("img", { src: _imports_0 }, null, -1),
                _createTextVNode(" Ver productos ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(ButtonAction, { "type-button": 'btn btn-primary' }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("img", { src: _imports_1 }, null, -1),
                _createTextVNode(" Comprar producto ")
              ])),
              _: 1
            }),
            _cache[2] || (_cache[2] = _createElementVNode("i", null, "* Proximamente", -1))
          ])
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("a", {
          href: "#brands",
          class: "buttom-lower"
        }, [
          _createElementVNode("img", {
            src: _imports_2,
            alt: "icon-bottom",
            class: "arrow-lower"
          })
        ], -1))
      ])
    ])
  ], 64))
}
}

})