import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


import router from "@/router"


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonAction',
  props: {
    typeButton: {
        type: String,
    },
    view: {
        type: String
    }
},
  setup(__props) {



function navigation(view) {
    router.push({name: view});
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(__props.typeButton),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (navigation(__props.view)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})