import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "product-text" }
const _hoisted_3 = { class: "product-name" }
const _hoisted_4 = { class: "product-type" }
const _hoisted_5 = { class: "product-description" }
const _hoisted_6 = { class: "btn btn-request-catalogue" }


//import emailjs from '@emailjs/browser';
import { ref } from 'vue';
import { Modal } from 'ant-design-vue';

import 'ant-design-vue/dist/reset.css';

//const modal1Visible = ref<boolean>(false);

export default /*@__PURE__*/_defineComponent({
  __name: 'CardArticleProduct',
  props: {
  objectProduct: {
    type: Object,
    required: true
  }
},
  setup(__props) {

const modal2Visible = ref<boolean>(false);

/*const setModal1Visible = (open: boolean) => {
  modal1Visible.value = open;
};*/



/*function nextDetailCategorieView(current: string, old: string) {
  router.push({
    name: `catalogueSubCategorie`,
    params: {
      categorie: current,
      subcategorie: old
    }
  })
}*/

/*function sendEmail() {
  const templateParams = {
    user_email: "romario10raymundo@gmail.com",
    name: "Rodamientos Huanuco",
    surnames: "Huanuco", // Archivo PDF en base64
    };

    emailjs.send("service_p7vfhzp","template_4l1f7zb", templateParams)
    .then((result) => {
        console.log('Email sent:', result.text);
    },(error) => {
        console.log('Failed to send email:', error.text);
    });
}*/

function sendMessageWhatsAppProduct(categorie: string, type: string) {
  const usersWhatsApp = [998247832, 998247830, 993381769]
  const linkWhatsApp = `https://wa.me/51${usersWhatsApp[Math.floor(Math.random() * usersWhatsApp.length)]}?text=Hola Rodamientos Huanuco, me intersa ${categorie} de tipo ${type}`;
  window.open(linkWhatsApp, '_blank');
}

/*function sendMessageWhatsAppCatalogue(categorie: string, type: string) {
  const userWhatsApp = 998247832
  const linkWhatsApp = `https://wa.me/51${userWhatsApp}?text=Hola Rodamientos Huanuco, quiero solicitar el catalogo de ${categorie} de tipo ${type}`;
  window.open(linkWhatsApp, '_blank');
}*/



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", null, [
    _createElementVNode("figure", null, [
      _createElementVNode("img", {
        src: __props.objectProduct.imgUrl ? __props.objectProduct.imgUrl : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png',
        alt: "Imagen"
      }, null, 8, _hoisted_1),
      _createElementVNode("figcaption", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(__props.objectProduct.name), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(__props.objectProduct.type), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(__props.objectProduct.description), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            class: "consulta",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (sendMessageWhatsAppProduct(_ctx.$route.params.categorie as string, __props.objectProduct.name)))
          }, " Seguir consultando "),
          _createVNode(_unref(Modal), {
            open: modal2Visible.value,
            "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((modal2Visible).value = $event)),
            title: "Solicitud de catalogo",
            centered: "",
            onOk: _cache[2] || (_cache[2] = ($event: any) => (modal2Visible.value = false))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("input", {
                type: "email",
                placeholder: "correo@gmail.com",
                style: {"width":"100%","padding-inline":"10px","border-radius":"10px","font-size":"18px"}
              }, null, -1)
            ])),
            _: 1
          }, 8, ["open"])
        ])
      ])
    ])
  ]))
}
}

})