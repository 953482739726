import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section-catalogue" }
const _hoisted_2 = { class: "products" }
const _hoisted_3 = { class: "content-cards-catalogue" }
const _hoisted_4 = { class: "cards" }
const _hoisted_5 = { class: "cards" }

import { ref, Ref } from "vue";
  
  // COMPONENTS
  import CardCatalogue from "@/components/shared/cards/CardCatalogue.vue";
  import data from '@/data/dataMain.json';
  //import { categorie } from "@/types/TypeCategorie";
  
  // SWIPER
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  // STYLE
  import './SectionCatalogue.style.scss';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'SectionCatalogue',
  setup(__props) {

  let modules = ref([Pagination, Navigation, Virtual])
  const repuestos: Ref = ref([])
  const outilsSKF: Ref = ref([])
  
  outilsSKF.value = data.categories.filter(item => item.type === "others")
  repuestos.value = data.categories.filter(item => item.type != "others")
  
  
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "header-section-catalogue" }, [
        _createElementVNode("div", { class: "text-catalogue" }, [
          _createElementVNode("p", null, "Presentamos"),
          _createElementVNode("h2", null, "Nuestros Productos")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(Swiper), {
            modules: _unref(modules),
            spaceBetween: 20,
            navigation: true,
            virtual: true,
            breakpoints: {
            0: {
              slidesPerView: 2
            },
            600: {
              slidesPerView: 2
            },
            980: {
              slidesPerView: 3
            },
            1200: {
              slidesPerView: 3
            },
            1420: {
              slidesPerView: 4
            }
          },
            class: "mySwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(repuestos.value, (item, index) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  key: index,
                  virtualIndex: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(CardCatalogue, {
                      dataCategorie: item,
                      showImage: true
                    }, null, 8, ["dataCategorie"])
                  ]),
                  _: 2
                }, 1032, ["virtualIndex"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Swiper), {
            modules: _unref(modules),
            slidesPerView: 5,
            spaceBetween: 30,
            navigation: true,
            virtual: true,
            breakpoints: {
              0: {
                slidesPerView: 2
              },
              600: {
                slidesPerView: 2
              },
              980: {
                slidesPerView: 3
              },
              1200: {
                slidesPerView: 3
              },
              1420: {
                slidesPerView: 4
              }
            },
            class: "mySwiper"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(outilsSKF.value, (item, index) => {
                return (_openBlock(), _createBlock(_unref(SwiperSlide), {
                  key: index,
                  virtualIndex: index
                }, {
                  default: _withCtx(() => [
                    _createVNode(CardCatalogue, {
                      dataCategorie: item,
                      showImage: true
                    }, null, 8, ["dataCategorie"])
                  ]),
                  _: 2
                }, 1032, ["virtualIndex"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modules"])
        ])
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bearing" }, null, -1))
  ]))
}
}

})