import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]




export default /*@__PURE__*/_defineComponent({
  __name: 'BrandIcon',
  props: {
    urlImg: {
        type: String,
        required: true
    }
},
  setup(__props) {
  



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      src: __props.urlImg,
      alt: "",
      class: "logo-brand"
    }, null, 8, _hoisted_1)
  ]))
}
}

})