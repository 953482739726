import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "alt-nameproduct"
}
const _hoisted_3 = { key: 2 }


import { useNavbarStore } from "@/store/navbar";
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardCatalogue',
  props: {
    dataCategorie: {
        type: Object,
        required: true
    },
    isSelected: {
      type: String
    },
    showImage: {
      type: Boolean
    }
},
  setup(__props) {

const navStore = useNavbarStore();



function navCategorieCatalogue(categorie: string) {
  router.push({name: `catalogueCategorie`, params: {
    categorie: categorie.toLowerCase()
  }});
  navStore.changeStyleNavbar("light");
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("article", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (navCategorieCatalogue(__props.dataCategorie.name))),
    class: _normalizeClass(["card-catalogue", {selected: __props.isSelected?.toLowerCase() == __props.dataCategorie.name.toLowerCase()}])
  }, [
    _createElementVNode("figure", null, [
      (__props.showImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: __props.dataCategorie.img ? __props.dataCategorie.img : 'https://upload.wikimedia.org/wikipedia/commons/a/a3/Image-not-found.png',
            alt: ` Imagen de ${__props.dataCategorie.name}`
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      (!__props.dataCategorie.img && __props.showImage)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.dataCategorie.name.toUpperCase()), 1))
        : _createCommentVNode("", true),
      (!__props.showImage)
        ? (_openBlock(), _createElementBlock("figcaption", _hoisted_3, [
            _createElementVNode("p", null, _toDisplayString(__props.dataCategorie.name), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})