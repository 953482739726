import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "section-brands" }
const _hoisted_2 = { class: "section-content-brands" }

import BrandIcon from '@/components/shared/brand/BrandIcon.vue';
  import { ref } from 'vue';
  import dataMain from '@/data/dataMain.json';
  
  // SWIPER
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'SectionBrands',
  setup(__props) {

  let modules = ref([Pagination, Navigation, Virtual])
  
  const brands = ref(dataMain.brands);
  
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-section-brands" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Swiper), {
        modules: _unref(modules),
        spaceBetween: 10,
        navigation: true,
        virtual: true,
        breakpoints: {
            0: {
              slidesPerView: 1
            },
            300: {
              slidesPerView: 3
            },
            380: {
              slidesPerView: 4
            },
            600: {
              slidesPerView: 5
            },
            980: {
              slidesPerView: 6
            },
            1200: {
              slidesPerView: 8
            },
            1420: {
              slidesPerView: 10
            }
          },
        class: "mySwiper"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(brands.value, (item, index) => {
            return (_openBlock(), _createBlock(_unref(SwiperSlide), {
              key: index,
              virtualIndex: index
            }, {
              default: _withCtx(() => [
                _createVNode(BrandIcon, {
                  urlImg: item.img,
                  class: "item-brand"
                }, null, 8, ["urlImg"])
              ]),
              _: 2
            }, 1032, ["virtualIndex"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"])
    ])
  ]))
}
}

})