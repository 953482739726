import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "view-categorie-detail" }
const _hoisted_2 = { class: "section-filter" }
const _hoisted_3 = { class: "main-categorie" }
const _hoisted_4 = {
  key: 0,
  class: "items-catagoue"
}
const _hoisted_5 = {
  key: 0,
  class: "grid-items"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 1,
  class: "body-catalogue"
}

import CardArticleProduct from "@/components/shared/cards/CardArticleProduct.vue";
  import dataMain from "@/data/dataMain.json";
  import { ref } from "vue";
  import data from '@/data/dataMain.json';
  
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  
  // Import Swiper styles
  import 'swiper/css';
  
  import 'swiper/css/pagination';
  import 'swiper/css/navigation';
  import 'swiper/css/virtual';
  
  import { Pagination, Navigation, Virtual } from 'swiper/modules';
  import CardCatalogue from "@/components/shared/cards/CardCatalogue.vue";
  
  
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'CatalogueView',
  setup(__props) {

  let modules = ref([Pagination, Navigation, Virtual])
  
  let categories = ref(data.categories)
  
  
  
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h1", null, [
      _cache[0] || (_cache[0] = _createTextVNode("PRESENTACION DE ")),
      _createElementVNode("span", null, _toDisplayString(_ctx.$route.params.categorie ?  `${_ctx.$route.params.categorie}` : ''), 1)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Swiper), {
        modules: _unref(modules),
        slidesPerView: 5,
        spaceBetween: 10,
        navigation: true,
        virtual: true,
        breakpoints: {
              0: {
                slidesPerView: 2
              },
              600: {
                slidesPerView: 4
              },
              980: {
                slidesPerView: 5
              },
              1200: {
                slidesPerView: 6
              },
              1420: {
                slidesPerView: 7
              }
            },
        class: "mySwiper"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (item, index) => {
            return (_openBlock(), _createBlock(_unref(SwiperSlide), {
              key: index,
              virtualIndex: index
            }, {
              default: _withCtx(() => [
                _createVNode(CardCatalogue, {
                  dataCategorie: item,
                  isSelected: _ctx.$route.params.categorie as string
                }, null, 8, ["dataCategorie", "isSelected"])
              ]),
              _: 2
            }, 1032, ["virtualIndex"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modules"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _cache[2] || (_cache[2] = _createStaticVNode("<aside class=\"list-subcategories\" data-v-2d815ccb><div data-v-2d815ccb><input type=\"radio\" id=\"automotriz\" name=\"drone\" value=\"automotriz\" data-v-2d815ccb><label for=\"automotriz\" data-v-2d815ccb> Automotriz</label></div><div data-v-2d815ccb><input type=\"radio\" id=\"industrial\" name=\"drone\" value=\"industrial\" data-v-2d815ccb><label for=\"industrial\" data-v-2d815ccb> Industrial</label></div></aside>", 1)),
      (_ctx.$route.params.categorie)
        ? (_openBlock(), _createElementBlock("aside", _hoisted_4, [
            (_unref(dataMain).productsCatalogue[_ctx.$route.params.categorie.toUpperCase()] as string)
              ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataMain).productsCatalogue[_ctx.$route.params.categorie.toUpperCase()] as string, (item, index) => {
                    return (_openBlock(), _createBlock(CardArticleProduct, {
                      key: index,
                      "object-product": item
                    }, null, 8, ["object-product"]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("section", _hoisted_6, _cache[1] || (_cache[1] = [
                  _createElementVNode("p", null, "No hay productos para mostrar", -1)
                ])))
          ]))
        : (_openBlock(), _createElementBlock("aside", _hoisted_7, " Seleccionar algun repuesto "))
    ])
  ]))
}
}

})