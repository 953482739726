import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/whatsapp-link.svg'


const _hoisted_1 = { class: "blond-whatsapp" }


export default /*@__PURE__*/_defineComponent({
  __name: 'BlondWhatsapp',
  setup(__props) {


function showBoxMessage() {
  const usersWhatsApp = [998247832]
  const linkWhatsApp = `https://wa.me/51${usersWhatsApp[Math.floor(Math.random() * usersWhatsApp.length)]}?text=Hola Rodamientos Huánuco, estoy interesado en sus repuestos industriales y automotrices. ¿Podrían brindarme más información?`;
  window.open(linkWhatsApp, '_blank');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "alert-message",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (showBoxMessage()))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "Icon WhatsApp"
      }, null, -1)
    ]))
  ]))
}
}

})