import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../../../../public/logo-rodamientos-huanuco.png'
import _imports_1 from '@/assets/icons/icon-call.svg'
import _imports_2 from '@/assets/icons/icon-mail.svg'
import _imports_3 from '@/assets/icons/icon-menu.svg'
import _imports_4 from '@/assets/icons/icon_close.svg'


const _hoisted_1 = { class: "content-nav" }
const _hoisted_2 = { class: "options options-left" }
const _hoisted_3 = { class: "btn-menu" }

import BarContact from "@/components/shared/bars/BarContact.vue";
import { ref, Ref, onMounted, onUpdated } from "vue";
import { useRoute } from "vue-router";
import { useNavbarStore } from "@/store/navbar";
import data from "@/data/dataMain.json";
import './NavWeb.style.scss';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavWeb',
  setup(__props) {

const route = useRoute();
const storeNavbar = useNavbarStore();

let optionsMenu = ref<HTMLElement | null>(null);
let btnMenuBar = ref<HTMLElement | null>(null);
let btnMenuClose = ref<HTMLElement | null>(null);
let shadeMenu = ref<HTMLElement | null>(null);
let navbar: Ref<HTMLElement | null> = ref(null);
//let optionsCatalogue = ref<HTMLElement | null>(null);
const categories = ref();



categories.value = data.categories.map((item) => {
  return data.productsCatalogue[item.name.toUpperCase()]
    ? {
        categorie: item.name,
        subCategories: data.productsCatalogue[item.name.toUpperCase()],
      }
    : { categorie: item.name };
});


function clickOptionMenu(): void {
  if (optionsMenu.value?.className.includes("active")) {
    optionsMenu.value?.classList.remove("active");
  }
}

function changeBgNavbarTwo() {
  if (route.name != "home") {
    storeNavbar.changeStyleNavbar("light");
  } else {
    storeNavbar.changeStyleNavbar("transparent");
  }

  if (navbar.value) {
    navbar.value.className = `navbar ${storeNavbar.style}`;
  }
}

onMounted(() => {
  changeBgNavbarTwo();
});

onUpdated(() => {
  changeBgNavbarTwo();
})



function showMenu() {
  // Close Menu
  if (optionsMenu.value?.className.includes("active")) {
    optionsMenu.value?.classList.remove("active");
  }
  // Open Menu
  else {
    optionsMenu.value?.classList.add("active");
  }
}

/*function showOptionsCatalogue(e): void {
  if (optionsCatalogue.value?.className.includes("active")) {
    optionsCatalogue.value?.classList.remove("active");
    return;
  }
  optionsCatalogue.value?.classList.add("active");
}*/


function detectedScrollBgNavbar() {
  window.addEventListener("scroll", () => {
  if (route.name == "home") {
    //const screenHeight = window.screen.availHeight;

    if (window.scrollY >= 10) {
      storeNavbar.changeStyleNavbar("light");
    } else {
      storeNavbar.changeStyleNavbar("transparent");
    }

    if (navbar.value) {
      navbar.value.className = `navbar ${storeNavbar.style}`;
    }
  }
});
}
detectedScrollBgNavbar();


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(BarContact),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", {
        ref_key: "navbar",
        ref: navbar,
        class: "navbar transparent"
      }, [
        _createElementVNode("div", {
          class: "shade-nav",
          ref_key: "shadeMenu",
          ref: shadeMenu
        }, null, 512),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: "https://res.cloudinary.com/dyikvzcke/image/upload/v1727023877/logo-rodamientoshuanuco-removebg-preview_fyvt3v.png",
                alt: "Logo Rodamientos Huanuco",
                class: "logo-large"
              }, null, -1),
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Logo Rodamientos Huanuco",
                class: "logo-small"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", {
          class: "options options-right",
          ref_key: "optionsMenu",
          ref: optionsMenu
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("div", { class: "item-option item-search" }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Icono de busqueda"
            }),
            _createElementVNode("p", null, "998 247 832 ")
          ], -1),
          _createElementVNode("div", { class: "item-option item-search" }, [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "Icono de busqueda"
            }),
            _createElementVNode("p", null, "huanuco@skfhuanuco.com")
          ], -1)
        ]), 512),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showMenu()))
          }, [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "Menu",
              class: "menu",
              ref_key: "btnMenuBar",
              ref: btnMenuBar
            }, null, 512),
            _createElementVNode("img", {
              src: _imports_4,
              alt: "Close",
              class: "close",
              ref_key: "btnMenuClose",
              ref: btnMenuClose
            }, null, 512)
          ])
        ])
      ], 512)
    ]),
    _createElementVNode("div", {
      class: "content-menubar",
      ref_key: "optionsMenu",
      ref: optionsMenu
    }, [
      _createElementVNode("a", {
        ref_key: "btnMenuClose",
        ref: btnMenuClose,
        class: "item-close-menu",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (showMenu()))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("img", { src: _imports_4 }, null, -1)
      ]), 512),
      _createVNode(_component_router_link, {
        to: "/productos",
        class: "item-option",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (clickOptionMenu()))
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Productos")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/acerca-de",
        class: "item-option",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (clickOptionMenu()))
      }, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode("Acerca de")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/contacto",
        class: "item-option",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (clickOptionMenu()))
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("Contacto")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/tienda",
        class: "btn btn-store"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode("Tienda")
        ])),
        _: 1
      })
    ], 512)
  ], 64))
}
}

})