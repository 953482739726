import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "section-statistic" }
const _hoisted_2 = { class: "content-article" }
const _hoisted_3 = { class: "text-counter" }
const _hoisted_4 = { class: "content-article" }
const _hoisted_5 = { class: "text-counter" }
const _hoisted_6 = { class: "content-article" }
const _hoisted_7 = { class: "text-counter" }
const _hoisted_8 = { class: "content-article" }
const _hoisted_9 = { class: "text-counter" }

import { ref, Ref } from "vue";

type statistic = {
  year: number,
  clients: number,
  product: number,
  delivery: number,
}



export default /*@__PURE__*/_defineComponent({
  __name: 'SectionStatistic',
  setup(__props) {

let dataR: Ref<statistic> = ref({
year: 0,
clients: 0,
product: 0,
delivery: 0
});


function showCounterAutomatic(start: number, limit: number,key: keyof statistic, speed: number) {
dataR.value[key] = start
let tiempo = setInterval(() => {
  dataR.value[key] += 1;
  if( dataR.value[key] == limit) {
    clearInterval(tiempo);
  }
}, speed)
}

showCounterAutomatic(0,35, 'year', 80)
showCounterAutomatic(9969,10000, 'clients', 80)
showCounterAutomatic(19969, 20000,'product', 80)
showCounterAutomatic(169,200, 'delivery', 80)




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("article", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, "+ " + _toDisplayString(_unref(dataR).year), 1),
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-article" }, "Años de experiencia", -1))
        ])
      ]),
      _createElementVNode("article", null, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, " + " + _toDisplayString(_unref(dataR).clients.toString().length > 4 ? _unref(dataR).clients.toString().slice(0,2) : _unref(dataR).clients.toString().slice(0,1)) + "," + _toDisplayString(_unref(dataR).clients.toString().length > 4 ? _unref(dataR).clients.toString().slice(2,5) : _unref(dataR).clients.toString().slice(1,5)), 1),
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-article" }, "Clientes satisfechos", -1))
        ])
      ]),
      _createElementVNode("article", null, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, " + " + _toDisplayString(_unref(dataR).product.toString().length > 4 ? _unref(dataR).product.toString().slice(0,2) : _unref(dataR).product.toString().slice(0,1)) + "," + _toDisplayString(_unref(dataR).product.toString().length > 4 ? _unref(dataR).product.toString().slice(2,5) : _unref(dataR).product.toString().slice(1,5)), 1),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-article" }, "Productos", -1))
        ])
      ]),
      _createElementVNode("article", null, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("p", _hoisted_9, "+ " + _toDisplayString(_unref(dataR).delivery), 1),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-article" }, "Envios realizados", -1))
        ])
      ])
    ])
  ]))
}
}

})